import React from "react"
import { graphql } from "gatsby"
// import { RenderModules } from 'src/utils/renderModules'
import SEO from "src/components/SEO"
import Header from "src/components/Header"
import Footer from "src/components/Footer"
import ComponentRenderer from "src/components/ComponentRenderer"
import ArticleGrid from "src/components/ArticleGrid"
import { slugify } from "src/utils/format"

const Category = ({ data }) => {
  const page = data?.sanityCategory
  const path = "blog/categories/" + slugify(page.title)
  const blogPage = data?.blogPage?.content?.main
  const { modules } = blogPage || []

  const { categoryPosts } = data
  const gridPosts = categoryPosts.edges

  return (
    <>
      <SEO pagePath={path} title={page.title} />
      <Header location={path} hideToggle={!!page.hideCustomerToggle} />

      <ArticleGrid
        prevTheme="default"
        posts={gridPosts}
        ctaBar={false}
        title={page.title}
        description={page.description}
        currentSlug={slugify(page.title)}
      />

      {modules?.map((item, index) => {
        const prevSection = modules[index - 1]
        const nextSection = modules[index + 1]
        let prevTheme = false
        let nextTheme = false
        if (prevSection && prevSection.width !== "fullWidth") {
          prevTheme = prevSection.theme || "default"
        }
        if (nextSection && nextSection.width !== "fullWidth") {
          nextTheme = nextSection.theme || "default"
        }
        if (!item.theme && item.width !== "fullWidth") {
          item.theme = "default"
        }
        return (
          <ComponentRenderer
            item={item}
            key={item?._key || "section-" + index}
            nextTheme={nextTheme}
            prevTheme={prevTheme}
            isFirstSection={index === 0}
            isLastSection={index === modules.length - 1}
          />
        )
      })}
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    sanityCategory(id: { eq: $id }) {
      _key
      title
      description
    }
    categoryPosts: allSanityPost(
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
    ) {
      edges {
        node {
          ...Post
        }
      }
    }
    blogPage: sanityPage(content: { main: { type: { eq: "blog" } } }) {
      ...Page
    }
  }
`

export default Category
